export const NUMBER_GAME_OPTIONS = [
  { val: 2, label: "2 Games" },
  { val: 3, label: "3 Games" },
  { val: 5, label: "5 Games" },
  { val: 10, label: "10 Games" },
];

export const TURN_OPTIONS = [
  { val: "alternate", label: "Alternate Turn" },
  { val: 1, label: "Always Player 1" },
  { val: 2, label: "Always Player 2" },
];
