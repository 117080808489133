import { StatusBar } from "expo-status-bar";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Provider } from "react-redux";
import GameController from "./app/screens/GameController";
import Tournament from "./app/screens/Tournament";
import TournamentSetup from "./app/screens/TournamentSetup";
import { store } from "./app/store/game";

export default function App() {
  return (
    <Provider store={store}>
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.container}>
          <GameController />
          <StatusBar style="auto" />
        </View>
      </SafeAreaView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "lightgrey",
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
  },
  safeArea: {
    flex: 1,
    backgroundColor: "lightgrey",
  },
});
